<template>
  

    <div class="grid">
      <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  Menus
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  label="Add"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card" id="custom_card">
          <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <span class="p-float-label">
                  <InputText    v-model="menu_name"  > </InputText>
                  <label>Menu Name</label>
                </span>
                
              </div>
              <div class="my-2">
                &nbsp;
                <Button
                  icon="pi pi-search"
                  v-tooltip.bottom="'Search'"
                  class="btn_light_blue"
                  @click="search(1)"
                />
              </div>
              <div class="my-2">
                &nbsp;
                <Button class="btn_red" icon="pi pi-refresh" @click="search(2)" v-tooltip.bottom="'Refresh'"/>
              </div>
             
            </template>
  
            <template v-slot:end>
              <!-- <FileUpload
                mode="basic"
                accept="image/*"
                :maxFileSize="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block"
              /> -->
              <!-- <Button
                 
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                />&nbsp;
              <json-excel :data="product">
              <Button
               
                icon="pi pi-upload"
                v-tooltip.bottom="'export'"
               @click="exportCSV"
              />
              </json-excel> -->
            </template>
          </Toolbar>
          <!-- {{products}} -->
          <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true" showGridlines
          >
          <template #empty>
          <b style="text-align:center">No records found.</b>
        </template>
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                "
              >
                <h5 class="m-0"><b> Menu Master</b></h5>
                <!-- Total Records:{{totalRecords}} -->
                
           
                
              </div><div style="text-align:right;font-weight:bold"><span>Total Menus:</span>{{totalRecords}}</div>
            </template>
            
  
            <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[5]" >
              <template #body="{ data }"> 
                <span v-if="data.menu_status=='Active' ||data.menu_status=='active'"><badge class="btn_green" :value=data.menu_status severity="success"></badge></span>
              <span v-else-if="data.menu_status=='InActive' ||data.menu_status=='In Active'"><badge class="btn_yellow" :value=data.menu_status severity="warning"></badge></span>
              <span v-else-if="data.menu_status=='Delete' ||data.menu_status=='Deleted'"><badge class="btn_red" :value=data.menu_status severity="danger"></badge></span>
              <span v-else>-</span>
              </template>
            </Column>
  
            <Column header="Sr No" style="min-width: 5rem;text-align: center;">
              <template #body="{ index}">
              <span v-if="page_no==1">{{ +index+ +1 }}</span>
                <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
              </template>
            </Column>
            
            <Column
              header="Menu Name" :field="columns[1]"
              style="min-width: 5rem"
              >
              <template #body="{ data }">
                {{ data.menu_name }}
              </template>
            </Column>
            
            
            <Column header="menu url" :field="columns[2]" style="min-width: 10rem">
              <template #body="{ data }">
                <span v-if=" data.menu_url">{{data.menu_url}}</span>
                <span v-else>-</span>
                
              </template>
            </Column>
            
            <Column header="Menu Icon" :field="columns[4]" style="min-width: 5rem">
              <template #body="{ data }">
                <i :class="'text-2xl mb-2 pi pi-' + data.menu_icon"></i>
              </template>
            </Column>
           
  
            <Column header="Action" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                 
                  class="p-button-rounded p-button-help mr-2 btn_yellow"
                  v-tooltip.bottom="'Edit'"
                  @click="editProduct(slotProps.data)"
                />
                
                <Button
                  icon="pi pi-trash"
                  v-tooltip.bottom="'Delete'"
                  class="p-button-rounded p-button-warning mt-2 btn_red"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>





          <Dialog header="Menu" v-model:visible="productDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '70vw'}" :modal="true">
	    
        <div class="col-12">
              <div class="card">
                <div class="p-fluid formgrid grid">
                    <div class="field col-6 md:col-6">
                    <label for="menu_name">Menu Name</label>
                    <InputText
                      id="menu_name"
                      type="text"
                      v-model.trim="product.menu_name"
                      required="true"
                      autofocus
                      :class="{ 'p-invalid': submitted && !product.menu_name }"
                    />
                  </div>
                    <div class="field col-6 md:col-6">
                    <label for="menu_url">Menu Url</label>
                    <InputText
                      id="menu_url"
                      type="text"
                      v-model.trim="product.menu_url"
                      required="true"
                      autofocus
                      :class="{ 'p-invalid': submitted && !product.menu_url }"
                    />
                    
                    
                  </div>
                  <div class="field col-6 md:col-6">
                    <label for="menu_parent">Parent Menu</label>
                    <Dropdown
                      id="status" filter
                      v-model="parent_selectstatus"
                      :options="parent_dropdownItems"
                      :value="parent_dropdownItems.value"
                      optionLabel="name"
                      placeholder="Select One"
                      :class="{ 'p-invalid': submitted && !parent_selectstatus }"
                      required="true"
                    ></Dropdown>
                    
                    
                  </div>



                <div class="field col-6 md:col-6">
                    <label for="menu_icon">Menu Icon</label>
                    <Dropdown 
                    
                    v-model="pick_icon" 
                    :options="icon_dropdown" 
                    optionLabel="name" 
                    filter 
                    placeholder="Select Start Point" 
                    >
            <template #value="slotProps">
              <div class="country-item country-item-value" v-if="slotProps.value">
                <i :class="'text-2xl mb-2 pi pi-' + slotProps.value.name"></i> &nbsp; {{slotProps.value.name}}
                <!-- <div>{{slotProps.value.name}}</div> -->
              </div>
                <span v-else>
                  <!-- {{slotProps.placeholder}} -->
                  <i :class="'text-2xl mb-2 pi pi-' + slotProps.value.name"></i> &nbsp; {{slotProps.value.name}}
                </span>
            </template>
            <template #option="slotProps">
              <!-- <span>{{slotProps.option.name}}</span> -->
              <i :class="'text-2xl mb-2 pi pi-' + slotProps.option.name"></i> &nbsp; {{slotProps.option.name}}
            </template>
            
          </Dropdown>
                </div>


                <div class="field col-6 md:col-6">
                    <label for="status">Status</label>
                    <Dropdown
                      id="status"
                      v-model="selectstatus"
                      :options="dropdownItems"
                      :value="dropdownItems.value"
                      optionLabel="name"
                      placeholder="Select One"
                      :class="{ 'p-invalid': submitted && !selectstatus }"
                      required="true"
                    ></Dropdown>
                  </div>

                  <div class="field col-6 md:col-6">
                    <label for="menu_sequence">Menu Sequence</label>
                    <InputText
                      id="menu_sequence"
                      type="text"
                      v-model.trim="product.menu_sequence"
                      required="true"
                      autofocus
                      :class="{ 'p-invalid': submitted && !product.menu_sequence }"
                    />
                    
                    
                  </div>

                  <div class="field col-6 md:col-6">
                    <label for="description">Menu Description</label>
                    <InputText
                      id="description"
                      type="text"
                      v-model.trim="product.description"
                      required="true"
                      autofocus
                      :class="{ 'p-invalid': submitted && !product.description }"
                    />
                    
                    
                  </div>



                </div>
                  </div>
                  </div>
                
        
        <template #footer>
		<Button v-if="product._id" label="Update" @click="addrole" icon="pi pi-check" class="p-button-warning btn_light_blue"/>
		<Button v-else label="add" @click="addrole" icon="pi pi-check" class="p-button-warning btn_light_blue"/>
    </template>
	</Dialog>
  
<!-- 
-----------------------------------------------------------------------------------------------------------   -->
          <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete <b>{{ product.user_role_name }}</b
                >?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteuser"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductsDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete the selected products?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductsDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteSelectedProducts"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { FilterMatchMode } from "primevue/api";
  import ProductService from "../service/ProductService";
  import JsonExcel from "vue-json-excel";
  import BreadcrumbService from "../service/BreadcrumbService";
  
  //import FormNewsMaster from "@/components/FormNewsMaster";
  //import * as Survey from "survey-vue";
  
  // import axios from "axios";
  import apis from "@/apis";
  import axios from "axios";
import { log } from 'util';
  
  export default {
    data() {
      return {
        place_details:'',
        end_place_details:'',
        searchme:'',
        name:'',
        page_no:1,
        isLoading: false,
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        file_attachment: "",

        icon_dropdown: [],
        
        menu_name:'',
       

        



        dropdownItems: [
          { name: "Active", value: "Active" },
          { name: "InActive", value: "InActive" },
          { name: "Deleted", value: "Deleted" },
        ],

        parent_dropdownItems:[],
        selectstatus: { name: "", value: "" },
        parent_selectstatus: { name: "", value: "" },
        pick_icon:{ name: "", value: "" },


        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        
        
        // selectstatus2: { name: "Motivational", value: "Motivational" },
        limit: 10,
        columns: [],
        head: [
          "Data Usages",
          "Network Connectivity",
          "Students",
          "Teachers",
          "Student Registration",
          "News Master",
          "Government Panel",
          "Feedback Master",
          "User Queries",
          "Schemes Master",
          "Notice Master",
          "Subject Master",
          "Teacher Schedule Master",
          "Teacher Schedule DE Master",
          "Query Types Master",
          "Sub Query Types Master",
          "Admin Users",
          "Application Roles",
          "Departments",
          "Cast Master",
          "Year Master",
          "District Master",
          "Student Attendance",
          "Student Document",
          "Teachers",
          "Radical ids",
          "Subject Topics",
          "Query Comments",
          "Student Submit Answers",
          "Mock Tests",
          "Mock Test Papers",
          "Mock Test Results",
          "Video",
          "Syllabus",
          "Course",
        ],
        pageno: 1,
        submitted: false,
        icons: null,
        totalRecords: 0,
        id: "",
        statuses: [
          { label: "INSTOCK", value: "instock" },
          { label: "LOWSTOCK", value: "lowstock" },
          { label: "OUTOFSTOCK", value: "outofstock" },
        ],

        BreadcrumbService : null,
breadcrumbHome: {},
breadcrumbItems: [],
      };
    },
    productService: null,
    
    async created() {
      this.productService = new ProductService();
      this.initFilters();

      this.BreadcrumbService = new BreadcrumbService();
	let Breadcrum_data = this.BreadcrumbService.menumaster();
	this.breadcrumbHome=Breadcrum_data.home;
	this.breadcrumbItems=Breadcrum_data.items;
      
    },
    async mounted() {
        
        fetch('data/icons.json', { headers: { 'Cache-Control' : 'no-cache' } }).then(res => res.json())
            .then(d => {
                let icons = d.icons;
                let data = icons.filter(value => {
                    return value.icon.tags.indexOf('deprecate') === -1;
                });
                data.sort((icon1, icon2) => {
                    if(icon1.properties.name < icon2.properties.name)
                        return -1;
                    else if(icon1.properties.name < icon2.properties.name)
                        return 1;
                    else
                        return 0;
                });
                
                
                
                this.icon_dropdown=[];
                for (let a = 0; a < data.length; a++) {
                    this.icon_dropdown.push( { name: data[a].properties.name, value: data[a].properties.name });
            
                }
                

            });

      // this.productService.getProducts().then(data => this.products = data);
      this.id = this.$route.params.id;
  
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if (this.totalRecords == 0) {
        await this.get_count();
      }
      await this.get_list();
     
      
      //alert(this.id);
    },
    watch: {
     async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    
    methods: {
      async search(count)
      { 
        
        count==2?this.menu_name='':'';
        await this.get_list();
        await this.get_count();
      },
        editProduct(product) {
        this.file_attachment = "";
        this.product = { ...product };
        this.productDialog = true;

        this.selectstatus.value = this.product.menu_status;
        this.selectstatus.name = this.product.menu_status;
        
        this.pick_icon.value = this.product.menu_icon;
        this.pick_icon.name = this.product.menu_icon;

        this.parent_selectstatus = { name: "", value: "" };
        if (this.product.parentmenu.length>0) {
        this.parent_selectstatus.value = this.product.parentmenu[0]._id;
        this.parent_selectstatus.name = this.product.parentmenu[0].parent_menu_name;
        }
        
        
        
        
        
        
      },
      
     
     
             isNumber(evt) {  
        const charCode = evt.which ? evt.which : evt.keyCode;  
        if (  
          charCode > 31 &&  
          (charCode < 48 || charCode > 57) &&  
          charCode !== 46  
        ) {  
          evt.preventDefault();  
        }  
      },  
  
      
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      get_count: function () {
        // var type='';
        // if(this.user_type_search!='')
        // {
        //   type=this.user_type_search.code;
        // }
        var data = {
        
        count: true,
        menu_name:this.menu_name
        
       
        };
        this.loading = true;
        var promise = apis.getMenuListMaster(data);
        promise.then((response) => {
          this.loading = false;
        //   console.log(response);
          this.totalRecords = response.data.data;
          //alert(this.totalRecords);
        });
  
        // console.log(data);
      },
      get_list: function () {
        
        // var type='';
        // if(this.user_type_search!='')
        // {
        //   type=this.user_type_search.code;
        // }
        var data = {
          limit: this.limit,
          page_no: this.page_no,
          count: false,
          menu_name:this.menu_name
  
        };
        this.loading = true;
        var promise = apis.getMenuListMaster(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
                
              this.columns = Object.keys(this.products[0]);
            }
          }

                this.parent_dropdownItems=[];
                for (let a = 0; a < response.data.data.length; a++) 
                {
                    this.parent_dropdownItems.push( { name: response.data.data[a].menu_name, value: response.data.data[a]._id });
            
                }

        });
  
        console.log(data);
      },
      formatCurrency(value) {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      },
      openNew() {
        this.product = {};
        this.submitted = false;
        this.productDialog = true;

        this.selectstatus = { name: "", value: "" };
        this.parent_selectstatus = { name: "", value: "" };
        this.pick_icon = { name: "", value: "" };
      },
      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },
      async addrole() {
        this.submitted = true;
        
        if (!this.product.menu_name) {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Menu Name",
            life: 3000,
          });
          return false;
        }
        
       
        
        // if (!this.product.menu_url) {
        //   this.$toast.add({
        //     severity: "error",
        //     summary: "error",
        //     detail: "Please Enter Menu URL",
        //     life: 3000,
        //   });
        //   return false;
        // }

        //parent menu
        // if (!this.parent_selectstatus.value) {
        //   this.$toast.add({
        //     severity: "error",
        //     summary: "error",
        //     detail: "Select Menu Parent Menu",
        //     life: 3000,
        //   });
        //   return false;
        // }
        if (!this.pick_icon.value) {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Menu Icon",
            life: 3000,
          });
          return false;
        }

        if (!this.selectstatus.value) {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Status",
            life: 3000,
          });
          return false;
        }
        if (!this.product.menu_sequence) {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Menu Sequence",
            life: 3000,
          });
          return false;
        }
        
         
      
  
        //edit
        if (this.product._id) {
          

            var data = {
          "_id":this.product._id,
          
          "menu_name": this.product.menu_name,
          "menu_url": this.product.menu_url,
          "menu_parent": this.parent_selectstatus.value,
          "menu_icon": "pi pi-fw pi-"+this.pick_icon.value,
          "menu_status": this.selectstatus.value,
          "menu_sequence": parseInt(this.product.menu_sequence),
          "description":this.product.description,
          "updated_at": "",
          "updated_by": ""
          };
         
          this.isLoadingModel = true;
          var promise = apis.editmenu(data);
          promise
            .then((responseapi) => {
              
              this.$swal(responseapi.data.message);
              this.isLoadingModel = false;
              this.productDialog = false;
              this.get_list();
              this.get_count();
            })
            .catch((error) => {
              //console.log(error);
              this.isLoadingModel = false;
              this.productDialog = false;
  
              this.$swal.fire(error.response.data);
              this.get_list();
              this.get_count();
            });
        }
        //add-----------------------------------------------------------------------------------------------------------------
        else {
          var data1 = {
          "menu_name": this.product.menu_name,
          "menu_url": this.product.menu_url,
          "menu_parent": this.parent_selectstatus.value,
          "menu_icon": "pi pi-fw pi-"+this.pick_icon.value,
          "menu_status": this.selectstatus.value,
          "menu_sequence": parseInt(this.product.menu_sequence),
          "description":this.product.description,
          "created_at": "",
         
          "created_by": localStorage.getItem("full_name"),
          
          };
          this.isLoadingModel = true;
          var promises = apis.addmenu(data1);
          promises
            .then((response) => {
              this.isLoadingModel = false;
             
              this.productDialog = false;
              this.$swal(response.data.message);
              this.get_list();
              this.get_count();
            })
            .catch((error) => {
              //console.log(error);
              this.isLoadingModel = false;
              this.productDialog = false;
  
              this.$swal.fire(error.response.data);
              this.get_list();
              this.get_count();
            });
        }
  
       
      },
      
      
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
      deleteProduct() {
        this.products = this.products.filter((val) => val.id !== this.product.id);
        this.deleteProductDialog = false;
        this.product = {};
        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Product Deleted",
          life: 3000,
        });
      },
  
      deleteuser() {
        if (this.product._id) {
          var data = {
            _id: this.product._id,
          };
          this.isLoadingModel = true;
          var promise = apis.deletemenumaster(data);
          promise.then((responseapi) => {
            this.isLoadingModel = false;
          
            if (responseapi.data.status == true) {
              // this.hideDialog1();
              this.deleteProductDialog = false;
              this.$swal(responseapi.data.message);
              this.get_list();
              this.get_count();
            } else {
              this.$swal("error to Delete Role");
            }
          });
        }
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            index = i;
            break;
          }
        }
        return index;
      },
      createId() {
        let id = "";
        var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      },
      
    async  exportCSV() {
        this.limit=this.totalRecords;
        this.get_list();
       await this.$refs.dt.exportCSV();
      },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      deleteSelectedProducts() {
        this.products = this.products.filter(
          (val) => !this.selectedProducts.includes(val)
        );
        this.deleteProductsDialog = false;
        this.selectedProducts = null;
        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Products Deleted",
          life: 3000,
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: {downloadExcel: JsonExcel},
  };
  </script>
  
  <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  @import '../assets/demo/documentation.scss';

.icons-list {
    i {
        color: var(--text-color-secondary);
    }
}
#custom_card {
  border-top: 3px solid #6b719b;
}
  </style>
  